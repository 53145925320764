<template>
  <div>
    <div class="row mb-3">
      <div class="col d-flex align-items-center">
        <h1 class="text-white mr-3 mb-0">
          {{ $t('pages.posts.show.title') }}
        </h1>
        <a
          :href="`https://instagram.com/p/${post.data.shortcode}`"
          rel="noopener"
          target="_blank"
        >
          <base-button size="sm" outline type="primary">
            {{ $t('pages.posts.show.post_button') }}
            <i class="fas fa-external-link-alt" />
          </base-button>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-table type="post-comments" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  async mounted() {
    await this.init()
  },

  computed: {
    ...mapGetters({
      post: 'posts/getPost',
      postComments: 'postComments/getPostComments',
    }),
  },

  methods: {
    ...mapActions({
      postShow: 'posts/show',
      postCommentsIndex: 'postComments/index',
    }),

    async init() {
      console.log(this.$route.params.postId)
      await this.postShow(this.$route.params.postId)
      await this.postCommentsIndex({ id: this.$route.params.postId })
    },
  },
}
</script>
